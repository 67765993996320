import styles from './app.module.css';
import Page from '../page/page'
import Modal from '../modal/modal';
import { useState } from 'react';

function App() {
  const [isModalShow, setIsModalShow] = useState(false);
  return (
    <div className={styles.app}>
      <Page openModal={() => setIsModalShow(true)} />
      <Modal isModalShow={isModalShow} closeModal={() => setIsModalShow(false)} />
    </div>
  );
}

export default App;
