import { useRef } from 'react';
import OrderDetails from '../order-details/order-details';
import Top from '../top/top';
import styles from './main.module.css';

function Main({ openModal }) {
  const myRef = useRef(null)

  return (
    <div className={styles.main}>
      <Top openModal={openModal} myRef={myRef} />
      <OrderDetails myRef={myRef} />
    </div>
  );
}

export default Main;
