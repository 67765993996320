import styles from './page.module.css';
import Header from '../header/header'
import Footer from '../footer/footer';
import Main from '../main/main';

function Page({ openModal }) {
  return (
    <div className={styles.page}>
      <Header />
      <Main openModal={openModal} />
      <Footer />
    </div>
  );
}

export default Page;
