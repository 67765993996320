import styles from './top.module.css';

function Top({ openModal, myRef }) {

  const handleShowDetailsClick = () => {
    myRef.current.scrollIntoView({
      behavior: 'smooth'
    })
  }

  return (
    <div className={styles.top}>
    <div>
      {/* <video autoplay="autoplay" playsinline="playsinline" loop="true" muted="true" src="/video/bg.webm"></video> */}
      <video playsInline="true" autoplay="autoplay" loop="true" muted="true" src="/video/mash.mp4">
    </video>
    </div>
    <div className={styles.shadow}>
      </div>
      <h1 className={styles.title}>MALIBU</h1 >
      <h2 className={styles.title}><span>M</span>ASH BAG</h2 >
      <div className={styles.button_container}>
        <button className={styles.order_button} id="button_order" type="button" onClick={openModal}>Заказать комплект</button>
        <button className={styles.show_details_button} id="button_show_details" onClick={handleShowDetailsClick} type="button">ПОСМОТРЕТЬ НАПОЛНЕНИЕ</button>
      </div>
    </div >
  );
}

export default Top;
