import { createPortal } from 'react-dom';
import styles from './detail-modal.module.css';

export const DetailModal = ({product, closeModal}) => {
    return createPortal(
        <div className={styles.overlay} id="overlay">
            <div className={styles.modal}>
                <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.button_container}>
                        <div className={styles.close_button_container} onClick={closeModal} >
                            <img src="./image/modal/close.svg" alt="close button" className={styles.close_button} />
                        </div>
                    </div>
                    <div className={styles.modal_body}>{product.detail ? product.detail() : null}</div>
                </div>
            </div>
        </div>, 
        document.body)
}