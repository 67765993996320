import Social from '../social/social';
import styles from './header.module.css';

function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.phone} >+7 (929) 730-99-99</div>
        <div className={styles.header_logo} >
          <img src="image/logo/logo.svg" alt="The Mash Store Logo" />
        </div >
        <Social isHeader />
      </div >
    </header >
  );
}

export default Header;
