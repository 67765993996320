import styles from './modal.module.css';

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

function Modal({ isModalShow, closeModal }) {

  const handleTelegramClick = () => {
    openInNewTab('https://t.me/themash');
  }

  const handleWhatsappClick = () => {
    openInNewTab('http://wa.me/79297309999');
  }

  // const handleViberClick = () => {
  //   openInNewTab('https://www.viber.com/ru/');
  // }


  return (
    <div className={`${styles.overlay} ${isModalShow && styles.display}`} id="overlay">
      <div className={styles.modal}>
        <div className={styles.modal_container}>
          <div onClick={closeModal} className={styles.button_container}>
            <div className={styles.close_button_container}>
              <img src="./image/modal/close.svg" alt="close button" className={styles.close_button} />
            </div>
          </div>
          <div className={styles.modal_header}>
            <div className={styles.modal_title}>Выберите мессенджер</div>
          </div>
          <div className={styles.modal_body}>
            <div className={styles.modal_body_info}>Напишите нам в любом мессенджеры мы
              ответим на все ваши вопросы и поможем вам!</div>
            <button className={styles.modal_button} onClick={handleTelegramClick}>
              <img
                src="image/modal/telegram.svg"
                alt="telegram" id="telegram"

                className={styles.mobile_social} />
              Telegram
            </button>
            <button className={styles.modal_button} onClick={handleWhatsappClick}>
              <img src="image/modal/whatsapp.svg" alt="whatsapp" id="whatsapp" className={styles.mobile_social} />
              whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
