import styles from './social.module.css';

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

function Social({ isHeader }) {

  const handleInstagramClick = () => {
    openInNewTab('https://www.instagram.com/themash.store');
  }

  const handleTelegramClick = () => {
    openInNewTab('https://t.me/themash');
  }

  return (
    <ul className={`${styles.social} ${isHeader ? styles.social_header : styles.social_footer}`}>
      <li>
        <img src="image/social/instagram.svg" alt="instagram" onClick={handleInstagramClick} />
      </li>
      <li>
        <img src="image/social/telegram.svg" alt="telegram" onClick={handleTelegramClick} />
      </li>
    </ul>
  );
}

export default Social;
