import Social from '../social/social';
import styles from './footer.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
      <Social isHeader={false} />
      <div className={styles.phone}>+7 (929) 730-99-99</div>
      <img className={styles.logo} src="image/logo/logo.svg" alt="The Mash Store Logo" />
    </footer>
  );
}

export default Footer;
