import { useState } from 'react';
import styles from './product.module.css';
import { DetailModal } from './detail-modal/detail-modal';

function Product({ product }) {
  const [modal, setModal] = useState(false);
  return (
    <div className={styles.product} onClick={() => product.detail ? setModal(!modal) : null}>
      <div className={styles.box}>
        <div className={styles.title}>
          {product.title}
        </div>
        <div className={styles.subtitle}>
          {product.subtitle}
        </div>
        <div className={styles.text}>
          {product.text}
        </div>
      </div >
      <img src={'/image/product/' + product.img} alt={product.img_alt} className={styles.product_img} />
      { modal && <DetailModal closeModal={() => setModal(false)} product={product}/> }
    </div >
  );
}

export default Product;
