import Product from '../product/product';
import styles from './order-details.module.css';

const product_list = [
  { title: 'DAVINES OI', subtitle: 'Молочко', img: 'oi.webp', img_alt: 'Yuzu Self Foaming', text: 'Содержит масло аннато, провитамин B5 и функциональный аминокомплекс, который великолепно кондиционирует волосы, оставляя мягкими, защищает их от стресса и повреждений в результате термического воздействия.',
  detail: () => 
    <div className={styles.details_modal}>
    <h1 className={styles.name_details}>DAVINES OI</h1>
    <h2 className={styles.subtitle_details}>Молочко</h2>
    <span className={styles.span_details}>OI – бестселлер Davines. Линия для абсолютной красоты волос и кожи головы. Все ее продукты содержат масло Аннато, которое предотвращает старение и усиливает блеск волос. Средства линии OI не содержат cульфатов и парабенов, смягчают волосы и облегчают расчесывание, придают мягкость и подходят для ежедневного использования.</span>
    <span className={styles.span_details}>Содержит масло аннато, провитамин B5 и функциональный аминокомплекс, который великолепно кондиционирует волосы, оставляя мягкими, защищает их от стресса и повреждений в результате термического воздействия.</span>
    {/* <li>Глянцевый эффект</li>
    <li>Для дисциплины</li>
    <li>Для защиты от ломкости</li>
    <li>Для плотности</li>
    <li>Для сохранения цвета</li>
    <li>Для термозащиты</li>
    <li>Для увлажнения</li>
    <li>Для частого мытья</li> */}
    </div>},
  { title: 'OUAI HAIRCARE Wave Spray', subtitle: 'Спрей', img: 'quai.webp', img_alt: 'Glow Face Moisture Mask ', text: 'Спрей для волос с рисовым протеином фиксирует распущенные волосы без морской соли, подходит для окрашенных и обработанных волос.',  
  detail: () => 
  <div className={styles.details_modal}>
  <h1 className={styles.name_details}>OUAI HAIRCARE Wave Spray</h1>
  <h2 className={styles.subtitle_details}>Спрей</h2>
  <span className={styles.span_details}>Невесомый спрей для волос Ouai Wave Spray легко и просто поможет уложить ваши волосы в непринуждённые волны, улучшает текстуру волос и придаёт им объём и блеск. В состав средства вместо морской соли входит экстракт рисового протеина, который позволяет надолго фиксировать форму волос и безопасен для окрашеных и покрытых кератином волос.</span>
  <span className={styles.span_details}>Лёгкий цветочный аромат содержит ноты бергамота, итальянского лимона, розы, майолии, лилии, ежевики, фиалки, кедра, янтаря, пачули, сандала и белого мускуса.</span>
  </div>},
  { title: 'RHODE', subtitle: 'Бальзам', img: 'rhode.webp', img_alt: 'Glow Face Moisture Mask ', text: 'Самые неуловимые и желанные бальзамы для губ! Восстанавливающее средство для губ, придающее губам естественную пухлость и мягкость - днем и ночью. Эта формула о которой Вы мечтали, придает губам блеск и питает и увлажняет сухую кожу.', 
  detail: () => 
  <div className={styles.details_modal}>
  <h1 className={styles.name_details}>Rhode Peptide Lip Treatment</h1>
  <h2 className={styles.subtitle_details}>Бальзам</h2>
  <span className={styles.span_details}>Самые неуловимые и желанные бальзамы для губ! Восстанавливающее средство для губ, придающее губам естественную пухлость и мягкость - днем и ночью. Эта формула о которой Вы мечтали, придает губам блеск и питает и увлажняет сухую кожу.</span>
  </div>},
  { title: ' Hurraw! Black Cherry Tinted Lip Balm', subtitle: 'Бальзам', img: 'hurr.webp', img_alt: 'Glow Face Moisture Mask ', text: 'Данный бальзам для губ направлен на интенсивное питание и защиту от воздействия различных внешних факторов. Бальзам придаст губам легкий красноватый цвет. Его можно использовать как летом, так и зимой.', 
  detail: () => 
  <div className={styles.details_modal}>
  <h1 className={styles.name_details}>Hurraw! Black Cherry Tinted Lip Balm</h1>
  <h2 className={styles.subtitle_details}>Бальзам</h2>
  <span className={styles.span_details}>Оттеночный бальзам для губ со вкусом вишни, Hurraw! Black Cherry Tinted Lip Balm. Данный бальзам для губ направлен на интенсивное питание и защиту от воздействия различных внешних факторов. Бальзам придаст губам легкий красноватый цвет. Его можно использовать как летом, так и зимой.</span>
  <span className={styles.span_details}>Оттенок черного вишневого бальзама может варьироваться из-за природы растительных ингредиентов и сезонных изменений, несмотря на наши усилия обеспечить однородность каждой партии.</span>
  </div>},
  { title: 'Weleda Birch Body Scrub', subtitle: 'Скраб', img: 'scrab.webp', img_alt: 'Skin Food', text: 'Мягко отшелушивает и разглаживает кожу. Не содержит синтетических очищающих средств или агрессивных веществ. Гранулы натурального растительного воска и чистые растительные масла мягко массируют кожу, стимулируя обновление клеток.',   
  detail: () => 
  <div className={styles.details_modal}>
  <h1 className={styles.name_details}>Weleda Birch Body Scrub</h1>
  <h2 className={styles.subtitle_details}>Скраб</h2>
  <span className={styles.span_details}>Березовый скраб для тела Weleda Birch Body Scrub мягко отшелушивает и разглаживает кожу. Не содержит синтетических очищающих средств или агрессивных веществ. Гранулы натурального растительного воска и чистые растительные масла мягко массируют кожу, стимулируя обновление клеток.</span>
  <span className={styles.span_details}>Органический экстракт листьев березы в сочетании с эфирными маслами самого высокого качества восстанавливает кожу и пробуждает чувства. Березовый скраб для тела Weleda Birch Body Scrub содержит органический экстракт листьев березы, известный своими очищающими свойствами и способностью выводить токсины. Результат — мягкая и шелковистая кожа.</span>
  </div>},
  { title: 'Proteos Hydra Plus', subtitle: 'Ампулы', img: '1.webp', img_alt: 'MartiDerm Proteos Hydra Plus', text: 'В современном мире, где наша кожа подвергается воздействию различных факторов, необходимо найти эффективные средства для ухода за ней. Одним из таких средств являются ампулы которые предлагают максимальное увлажнение и освежение кожи.',   
  detail: () => 
  <div className={styles.details_modal}>
  <h1 className={styles.name_details}>MartiDerm Proteos Hydra Plus</h1>
  <h2 className={styles.subtitle_details}>Ампулы</h2>
  <span className={styles.span_details}> Максимальное увлажнение и освежение кожи с помощью ампулы. В современном мире, где наша кожа подвергается воздействию различных факторов, таких как загрязнение окружающей среды и стресс, необходимо найти эффективные средства для ухода за ней. Одним из таких средств являются ампулы, которые предлагают максимальное увлажнение и освежение кожи.</span>
  <span className={styles.span_details}>Ампулы разработаны с использованием передовых технологий и содержат уникальные активные ингредиенты, которые помогают достичь глубокого увлажнения и омоложения кожи. Главный ингредиент - комплекс протеогликанов, который способствует увлажнению и повышению эластичности кожи. Он также помогает бороться с признаками старения кожи, такими как морщины и потеря упругости.</span>
  </div>},
  { title: 'Pharmaline Body Milk Anti-Age', subtitle: 'Молочко', img: 'palm.webp', img_alt: 'Glow Face Moisture Mask ', text: 'Это увлажняющее молочко для тела с Алоэ Вера, Коэнзимом Q10 и 10% карбамидом делает кожу упругой, эластичной и молодой.', 
  detail: () => 
  <div className={styles.details_modal}>
  <h1 className={styles.name_details}>Pharmaline Body Milk Anti-Age</h1>
  <h2 className={styles.subtitle_details}>Молочко</h2>
  <span className={styles.span_details}>Интенсивно увлажняющее молочко для тела придает коже упругость и эластичность. Входящие в состав Алоэ Вера и Коэнзим Q10 благодаря своим антиоксидантным свойствам обновляют кожу и способствуют предотвращению преждевременного старения.</span>
  <span className={styles.span_details}>10% содержание карбамида обеспечивает оптимальный уровень увлажнения и эффективно удерживает влагу в поверхностных слоях кожи. Подходит для всех типов кожи.</span>
  </div>},
  { title: 'Dr.Jart+ Dermask Water', subtitle: 'Маска', img: 'djart.webp', img_alt: 'Glow Face Moisture Mask ', text: 'Маска обладает успокаивающим и легким омолаживающим действием. поддерживает тонус и здоровье кожи, устраняет красноту и способствует заживлению воспалений.',   detail: () => 
  <div className={styles.details_modal}>
  <h1 className={styles.name_details}>Dr.Jart+ Dermask Water</h1>
  <h2 className={styles.subtitle_details}>Маска</h2>
  <span className={styles.span_details}>Гиалуроновая кислота дарит долговременное и высокоинтенсивное увлажнение кожи, защищает от обезвоживания и образования преждевременных морщинок, стимулирует клеточное обновление, заживляет ранки и трещинки.</span>
  <span className={styles.span_details}>Экстракт морских водорослей обеспечивает защиту кожи от вредных внешних агрессивных воздействий. Улучшает синтез коллагена, придает коже эластичность и тонус, увлажняя и смягчая ее.</span>
  <span className={styles.span_details}>Акваксил - комплекс глюкозы и ксилита, увлажняющий и восстанавливающий кожу, предотвращающий потерю воды, смягчающий эпидермис, уменьшающий шелушения и улучшающий текстуру кожи.</span>
  </div>},
]
function OrderDetails({ myRef }) {
  return (
    <div className={styles.order_details} ref={myRef}>
      <div className={styles.title_container}>
        <h2 className={styles.title}>НАПОЛНЕНИЕ</h2>
      </div>
      <div className={styles.list_container}>
        <div className={styles.list}>
          {product_list.map((product, id) => <Product product={product} key={id} />)}
        </div>
      </div>
    </div >
  );
}

export default OrderDetails;
